<template>
  <div>
    <div class="classOrder">
      <!-- 标题 订单信息-->
      <div class="wrapsTit">选择套餐</div>
      <div class="detail">
        <div class="wrapsTits">套餐名称</div>
        <div class="wrapsCon">
          <img class="warp-img" :src="detail.img" />
          <div class="w-b-100">
            <div class="content-title">{{ detail.tiitle }}</div>
            <div class="content-tips">组合课程</div>
          </div>
          <div class="content-price"><span class="icon">￥</span>{{ detail.price }}</div>
        </div>
      </div>
      <div class="detail m-t-20">
        <div class="flex-between-c" v-if="!onRead">
          <div class="wrapsTits">已选类型</div>
          <div class="keep-learn" @click="onRead = true">管理</div>
        </div>
        <div class="flex-between-c" v-if="onRead">
          <div class="flex-c">
            <div class="keep-learn m-r-8" @click="chooseClick(undefined, true)">全选</div>
            <div class="keep-learn" @click="chooseClick(undefined, false)">取消全选</div>
          </div>
          <div class="keep-learn" @click="onRead = false">取消管理</div>
        </div>
        <!-- 组合套餐 -->
        <div class="docList">
          <div class="item" v-show="item.kcLIst.length > 0" v-for="(item, index) in kcList" :key="index">
            <div class="docList-title w-b-100" @click="chooseClick(undefined, item.Allchppse ? false : true, index)">
              <div class="flex-c ellipsis">
                <img v-if="item.Allchppse" class="img" src="@/assets/img/user/sure.png" />
                <div v-else class="img-no"></div>
                {{ item.kcName }}
              </div>
            </div>
            <div class="docList-item" @click="chooseClick(kcitem)" v-for="(kcitem, kcindex) in item.kcLIst"
              :key="kcindex">
              <img v-if="choose.kcides.includes(kcitem.id)" class="img" src="@/assets/img/user/sure.png" />
              <div v-else class="img-no"></div>
              {{ kcitem.yearName }}{{ kcitem.title }}
            </div>
          </div>
        </div>
      </div>
      <div class="but">
        <div class="price">
          合计:
          <div class="price-icon">￥</div>
          <div class="price-num">{{ allPrice }}</div>
        </div>
        <div class="but-item" @click="zhifu">提交订单</div>
      </div>
    </div>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getClass } from '@/api/home'
import wxPayment from './wxPayment.vue'
import { getInfo } from '@/api/cookies'
import { Know } from '@/api/know'
let know = new Know()
import { OrderClient } from '@/api/orderClient'
let orderClient = new OrderClient()
export default {
  components: { wxPayment },
  data() {
    return {
      classId: null,
      detail: {}, //班型详情
      choose: {
        kcdetail: [],
        kcides: [],
      },
      kcList: [],
      onRead: false,
      allPrice: 0,
      saveId: null,
    }
  },
  async created() {
    this.userInfo = getInfo()
    this.classId = this.$route.query.class
    this.saveId = this.$route.query.saveId ? this.$route.query.saveId : nu
    this.choose.kcides = this.$route.query.ids ? await this.$route.query.ids.split(',').map(Number) : []
    this.getDetail()
    if (this.saveId) {
      this.kcList = await JSON.parse(localStorage.getItem(`saveId${this.saveId}`))
    } else {
      await this.getyears()
    }
    for (let item of this.kcList) {
      if (item.choose && item.choose.length > 0) {
        for (let chil of item.choose) {
          if (this.choose.kcides.includes(chil.id)) {
            this.choose.kcdetail.push(chil)
          }
        }
      }
    }
    this.getPrice()

  },
  methods: {
    // 课程推荐详情
    async getDetail() {
      await getClass(this.classId).then((res) => {
        this.detail = res.data.BX
        this.$forceUpdate()
      })
    },
    /* 组合购买内容 */
    async getkc(kid) {
      let data = []
      await know.getClassesCourseBuyList(this.classId, undefined, kid, this.userInfo.id).then((res) => {
        if (res.code == 0) {
          for (let item of res.data.audio) {
            item.type = 7
            data.push(item)
            if (this.choose.kcides.includes(item.id)) {
              this.choose.kcdetail.push(item)
            }
          }

          for (let item of res.data.imageText) {
            item.type = 8
            data.push(item)
            if (this.choose.kcides.includes(item.id)) {
              this.choose.kcdetail.push(item)
            }
          }
          for (let item of res.data.live) {
            item.type = 2
            data.push(item)
            if (this.choose.kcides.includes(item.id)) {
              this.choose.kcdetail.push(item)
            }
          }
          for (let item of res.data.video) {
            item.type = 3
            data.push(item)
            if (this.choose.kcides.includes(item.id)) {
              this.choose.kcdetail.push(item)
            }
          }
        }
      })
      let price = this.choose.kcdetail.map((item, index) => {
        return item.entrantsPrice
      })
      var sum = 0
      for (let i of price) {
        sum += i
      }
      this.allPrice = sum
      return data
    },
    /* 获取知识套餐包含课程名 */
    async getyears() {
      await know.classesTable(this.classId, this.userInfo.id).then((res) => {
        if (res.code == 0) {
          this.kcList = res.data.array
        }
      })
      for (let item of this.kcList) {
        await this.getkc(item.kId).then((res) => {
          item.kcLIst = res.filter((item) => item.entrantsPass == 1)
          // item.kcLIst = res
          this.$forceUpdate()
        })
        item.Allchppse = true
        if (this.choose.kcides.length == 0 || item.kcLIst.length == 0) {
          item.Allchppse = false
        } else {
          for (let kc of item.kcLIst) {
            if (!this.choose.kcides.includes(kc.id)) {
              item.Allchppse = false
              break
            }
          }
        }
      }
      this.$forceUpdate()
    },
    /* 选中组合购买 */
    chooseClick(kcitem, ifAll, faindex) {
      if (!this.onRead) {
        return
      }
      if (kcitem) {
        if (this.choose.kcides.includes(kcitem.id)) {
          this.choose.kcdetail = this.choose.kcdetail.filter((item, index) => {
            return item.id != kcitem.id
          })
        } else {
          this.choose.kcdetail.push(kcitem)
        }
      } else if (faindex != undefined) {
        if (ifAll) {
          for (let item of this.kcList[faindex].kcLIst) {
            if (!this.choose.kcides.includes(item.id)) {
              this.choose.kcdetail.push(item)
            }
          }
        } else {
          let ids = this.kcList[faindex].kcLIst.map((item, index) => {
            return item.id
          })
          this.choose.kcdetail = this.choose.kcdetail.filter((item, index) => {
            return !ids.includes(item.id)
          })
        }
      } else {
        if (ifAll) {
          let data = []
          this.kcList.forEach((item) => {
            data = data.concat(item.kcLIst)
          })
          this.choose.kcdetail = data
        } else {
          this.choose.kcdetail = []
        }
      }

      this.choose.kcides = this.choose.kcdetail.map((item, index) => {
        return item.id
      })
      for (let item of this.kcList) {
        item.Allchppse = true
        for (let kc of item.kcLIst) {
          if (!this.choose.kcides.includes(kc.id)) {
            item.Allchppse = false
            break
          }
        }
      }
      this.getPrice()
    },
    getPrice() {
      let price = this.choose.kcdetail.map((item, index) => {
        return item.entrantsPrice
      })
      var sum = 0
      for (let i of price) {
        sum += i
      }
      this.allPrice = sum
      this.$forceUpdate()
    },
    zhifu() {
      this.$router.replace(`/orders/classSure?ids=${this.choose.kcides.join(',')}&class=${this.classId}`)
      if (this.userInfo) {
        let ids = this.choose.kcdetail.map((item, index) => {
          return item.id
        })
        let titles = this.choose.kcdetail.map((item, index) => {
          return item.title
        })
        let types = []
        for (let item of this.choose.kcdetail) {
          switch (item.type) {
            case 1:
              types.push(2)
              break
            case 2:
              types.push(3)
              break
            case 3:
              types.push(7)
              break
            case 4:
              types.push(8)
              break
            case 5:
              types.push(10)
              break
            default:
              break
          }
        }

        orderClient
          .addOrders(ids, this.userInfo.id, titles, types, this.detail.tiitle, this.detail.kind)
          .then((res) => {
            if (res.code == 0) {
              this.$router.push({
                name: '个人中心订单页',
                params: { orderNumber: res.msg },
              })
            } else {
              this.$message.error(res.msg)
            }
          })
      } else {
        Vue.prototype.goLoginView(true)
        // this.$router.push({ name: "登录页", params: { hzc: 1 } });
      }
    },
  },
}
</script>
<style lang="less" scoped>
.classOrder {
  width: 1220px;
  margin: 40px auto;

  // 标题
  .wrapsTit {
    font-size: 28px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #333333;
    line-height: 28px;
    margin-bottom: 32px;
  }

  .detail {
    background: #ffffff;
    border-radius: 8px 8px 8px 8px;
    padding: 40px;
  }

  // 小标题
  .wrapsTits {
    font-size: 16px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #333333;
    line-height: 16px;
  }

  .wrapsCon {
    width: 100%;
    height: 138px;
    background: #f5f7f9;
    border-radius: 8px 8px 8px 8px;

    margin-top: 20px;
    padding: 24px;
    display: flex;

    .warp-img {
      width: 160px;
      height: 90px;
      border-radius: 8px 8px 8px 8px;

      flex-shrink: 0;
      margin-right: 16px;
    }

    .content-title {
      width: 100%;
      font-size: 16px;
      font-family: Microsoft YaHei-Regular;
      font-weight: 400;
      color: #333333;
      line-height: 16px;
    }

    .content-tips {
      width: 64px;
      height: 20px;
      background: #fff2e9;
      border-radius: 2px 2px 2px 2px;
      opacity: 1;
      margin-top: 16px;
      font-size: 12px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #ff7b1c;
      line-height: 20px;
      text-align: center;
    }

    .content-price {
      font-size: 24px;
      font-family: Microsoft YaHei-Bold;
      font-weight: bold;
      color: #333333;
      line-height: 90px;

      .icon {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #333333;
        line-height: 14px;
      }
    }
  }
}

.docList {
  .item {
    border-radius: 8px 8px 8px 8px;
    overflow: hidden;
    border: 1px solid #dddddd;
    margin-top: 20px;
  }

  .img {
    width: 20px;
    height: 20px;
    opacity: 1;
    // border: 1px solid #9fa4ad;
    border-radius: 50%;
    margin-right: 12px;
  }

  .img-no {
    width: 20px;
    height: 20px;
    opacity: 1;
    border: 1px solid #9fa4ad;
    border-radius: 50%;
    margin-right: 12px;
  }

  .docList-title {
    height: 48px;
    background: #f5f7f9;
    border-radius: 8px 8px 8px 8px;

    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 48px;
    padding: 0px 20px;
  }

  .docList-item {
    height: 52px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    padding: 0px 20px;
    font-size: 14px;
    font-family: PingFang SC-Regular;
    font-weight: 400;
    color: #666666;
    line-height: 52px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}

/* 选中状态 */
.active {
  color: #ff5e51 !important;
  border: 1px solid #ff5e51 !important;
}

.but {
  height: 128px;
  background: #ffffff;
  width: 100%;
  opacity: 1;
  padding: 40px 29px;
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .price {
    font-size: 18px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #333333;
    line-height: 18px;
    margin-right: 40px;
    display: flex;
    align-items: flex-end;

    .price-icon {
      color: #ff5e51;
    }

    .price-num {
      color: #ff5e51;
      font-weight: Bold;
      line-height: 32px;
      font-size: 32px;
    }
  }

  .but-item {
    width: 170px;
    cursor: pointer;
    height: 48px;
    background: #ff5e51;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    line-height: 48px;
    float: right;
  }
}
</style>
